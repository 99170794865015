import Modal from "react-modal"
import { Row, Col, Button } from "react-bootstrap";
import TableWithFilters from "../tableWithFilters"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function GuestDifferencesModal({ isOpen, onAccepted, onCancel, guestsToBeInserted, guestsToBeRemoved }) {

    return <Modal ariaHideApp={false} isOpen={isOpen}>
        <Row>
            <Col xs={{ span: 1, offset: 10 }} >
                <Button disabled={guestsToBeInserted.length === 0 && guestsToBeRemoved.length === 0} onClick={onAccepted}>Importar</Button>
            </Col>
            <Col xs={{ span:1 }}>
                <Button onClick={onCancel}>Cancelar</Button>
            </Col>
        </Row>
        <Tabs
            defaultActiveKey="insert"
            id="uncontrolled-tab-example"
            className="mb-3">
            <Tab eventKey="insert" title={"A insertar (" + guestsToBeInserted.length + ")"}>
                <TableWithFilters data={guestsToBeInserted} columns={[
                    {
                        Header: 'Nombre',
                        accessor: 'name',
                        hasFilter: true
                    },
                    {
                        Header: 'Apellidos',
                        accessor: 'lastName',
                        hasFilter: true
                    },
                    {
                        Header: 'Confirmado',
                        accessor: 'confirmed',
                        Cell: ({ value }) => (
                            <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: value ? "green" : "red" }}></span>
                        ),
                        hasFilter: true
                    },
                    {
                        Header: 'Candidato Preboda',
                        accessor: 'preWeddingCandidate',
                        Cell: ({ value }) => (
                            <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: value ? "green" : "red" }}></span>
                        ),
                        hasFilter: true
                    }
                ]} />
            </Tab>
            <Tab eventKey="profile" title={"A eliminar (" + guestsToBeRemoved.length + ")"}>
                <TableWithFilters data={guestsToBeRemoved} columns={[
                    {
                        Header: 'Nombre',
                        accessor: 'name',
                        hasFilter: true
                    },
                    {
                        Header: 'Apellidos',
                        accessor: 'lastName',
                        hasFilter: true
                    },
                    {
                        Header: 'Confirmado',
                        accessor: 'confirmed',
                        Cell: ({ value }) => (
                            <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: value ? "green" : "red" }}></span>
                        ),
                        hasFilter: true
                    },
                    {
                        Header: 'Candidato Preboda',
                        accessor: 'preWeddingCandidate',
                        Cell: ({ value }) => (
                            <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: value ? "green" : "red" }}></span>
                        ),
                        hasFilter: true
                    }
                ]} />
            </Tab>
        </Tabs>
    </Modal>
}