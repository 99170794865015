import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { HashRouter, Routes, Route } from "react-router-dom";
import GuestsConfirmations from './guests/guestConfirmations';
import WeddingList from './weddingList/weddingList';
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" exact element={<WeddingList/>} />
        <Route path="/home" exact element={<WeddingList/>} />
        <Route path="/confirmations" element={ <GuestsConfirmations/> } />
        <Route path="/weddingList" element={ <WeddingList />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);