import React, { useRef } from 'react';
import { useTable, useFilters } from 'react-table';
import { Table, FormControl } from 'react-bootstrap';

function renderFilterIfNeeded(column) {
    if (column.hasFilter) {
        return <div>
            <FormControl value={column.filterValue || ''} onChange={e => column.setFilter(e.target.value)} />
        </div>
    }
}


function TableWithFilters({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useFilters);
    const tableRef = useRef(null);

    return (
        <Table ref={tableRef} {...getTableProps()} striped bordered hover>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                {renderFilterIfNeeded(column)}
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

export default TableWithFilters;
