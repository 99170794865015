import TableWithFilters from "../tableWithFilters"
import AppMenu from "../appMenu";
import { Button, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ArticleModal from "./articleModal";
import { addItem, updateItem, getItems, deleteItem, updateParticipation } from "./weddingListClient";
import ParticipationModal from "./participationsModal";

const newArticle = {
    title: "",
    file: null,
    hasTotal: false,
    total: 0,
    firstParticipationText: "",
    secondParticipationText: "",
    canBeBroughtToHome: true,
    link: "",
    id: null
}

const cloneNewArticle = { ...newArticle }

function WeddingList() {
    const [data, setData] = useState([])
    const [itemModalIsOpen, openItemModal] = React.useState(false);
    const [participationsModalIsOpen, openParticipationsModal] = React.useState(false);
    const [itemUpdates, forceUpdateItemState] = useState([]);
    const [currentArticle, setCurrentArticle] = useState(cloneNewArticle);

    useEffect(() => {
        updateTable();
    }, [itemUpdates])

    function updateTable() {
        getItems()
            .then(items => items.json())
            .then(items => items.sort((a, b) => a.id - b.id))
            .then(items => {
                setData(items);
            });
    }


    function deleteArticle(articleId) {
        deleteItem(articleId).then(() => {
            forceUpdateItemState([]);
        })
    }

    function editArticle(article) {
        setCurrentArticle(article)
        openItemModal(true)
    }

    function addArticle() {
        setCurrentArticle(cloneNewArticle)
        openItemModal(true);
    }


    function viewParticipations(article) {
        setCurrentArticle(article);
        openParticipationsModal(true);
    }


    function onItemModalAccepted(article) {
        openItemModal(false)
        if (!article.hasTotal) {
            article.total = 0;
        }
        if (!article.id) {
            addItem(article).then(() => { forceUpdateItemState([]) })
        }
        else {
            updateItem(article).then(() => { forceUpdateItemState([]) })
        }
        setCurrentArticle(cloneNewArticle);
    }

    function onItemModalCanceled() {
        openItemModal(false)
        setCurrentArticle(cloneNewArticle);
    }

    function onParticipationModalAccepted(participations) {
        openParticipationsModal(false);
        participations.forEach(participation => {
            updateParticipation(participation.itemId, participation);
        });
    }

    return (
        <div>
            <AppMenu />
            <Row className="mb-3">
                <Col xs={{ span: 1 }} >
                    <button type="button" className="btn btn-primary" onClick={() => addArticle()}>Añadir</button>
                </Col>
                <Col xs={1}>
                    <Button onClick={() => forceUpdateItemState([])} >Refrescar</Button>
                </Col>
            </Row>
            <ArticleModal
                isOpen={itemModalIsOpen}
                onAccepted={(article) => onItemModalAccepted(article)}
                onCancel={() => onItemModalCanceled()}
                initialArticle={currentArticle}
            />
            <ParticipationModal
                isOpen={participationsModalIsOpen}
                onAccepted={(participations) => onParticipationModalAccepted(participations)}
                onCancel={() => openParticipationsModal(false)}
                initialArticle={currentArticle}

            />
            <TableWithFilters data={data} columns={
                [
                    {
                        id: "delete",
                        Header: 'Acciones',
                        Cell: ({ cell }) => <div>
                            <Button variant="outline-danger" onClick={() => deleteArticle(cell.row.original.id)}>Borrar</Button>
                            <Button variant="outline-secondary" onClick={() => editArticle(cell.row.original)}>Editar</Button>
                        </div>
                    },
                    {
                        Header: 'Producto',
                        accessor: 'title'
                    },
                    {
                        Header: 'Foto',
                        accessor: 'picture',
                        Cell: ({ value }) => <a href={value}><img src={value} /></a>
                    },
                    {
                        Header: 'Tiene precio?',
                        accessor: 'hasTotal',
                        Cell: ({ value }) => (
                            <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: value ? "green" : "red" }}></span>
                        )
                    },
                    {
                        Header: 'Total',
                        accessor: 'total'
                    },
                    {
                        Header: 'Se puede traer?',
                        accessor: 'canBeBroughtToHome',
                        Cell: ({ value }) => (
                            <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: value ? "green" : "red" }}></span>
                        )
                    },
                    {
                        id: "participations",
                        Header: 'Participaciones',
                        Cell: ({ cell }) => <div>
                            <Button variant="outline-secondary" onClick={() => viewParticipations(cell.row.original)}>Participaciones</Button>
                        </div>
                    },
                    {
                        Header: 'Url',
                        accessor: 'link',
                        Cell: ({ value }) => <a href={value}>Link</a>
                    },
                    {
                        Header: 'Actualizado',
                        accessor: 'writtenAt',
                        Cell: ({ value }) => <span>{value.replace("T", " ")}</span>
                    },

                ]
            } />
        </div >
    );

}

export default WeddingList;
