import Modal from "react-modal"
import { Row, Col, Button, FormLabel, FormCheck, FormControl } from "react-bootstrap";
import { useState, useEffect } from "react";

export default function ArticleModal({ isOpen, onAccepted, onCancel, initialArticle }) {
    const [title, setTitle] = useState("");
    const [file, setFile] = useState(null);
    const [hasTotal, setHasTotal] = useState(false);
    const [total, setTotal] = useState(0);
    const [firstParticipationText, setFirstParticipationText] = useState("");
    const [secondParticipationText, setSecondParticipationText] = useState("");
    const [link, setLink] = useState("");
    const [preview, setPreview] = useState(null);
    const [id, setId] = useState(null);
    const [canBeBroughtToHome, setCanBeBroughtToHome] = useState(true);


    useEffect(() => {
        if (initialArticle) {
            setTitle(initialArticle.title);
            setHasTotal(initialArticle.hasTotal);
            setTotal(initialArticle.total);
            setFirstParticipationText(initialArticle.firstParticipationText);
            setSecondParticipationText(initialArticle.secondParticipationText);
            setLink(initialArticle.link);
            setId(initialArticle.id);
            setCanBeBroughtToHome(initialArticle.canBeBroughtToHome);
            setPreview(null);
        }
    }, [initialArticle])

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(e.target.files[0]);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
            console.log(file);
        } else {
            setPreview(null);
        }
    };

    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleHasTotalChange = (e) => setHasTotal(e.target.checked);
    const handleTotalChange = (e) => setTotal(e.target.value);
    const handleFirstDescriptionChange = (e) => setFirstParticipationText(e.target.value);
    const handleSecondtDescriptionChange = (e) => setSecondParticipationText(e.target.value);
    const handleUrlChange = (e) => setLink(e.target.value);
    const handleCanBeBroughtToHome = (e) => setCanBeBroughtToHome(e.target.checked);

    const handleAccepted = () => {
        if (!title || (hasTotal && !total) || !firstParticipationText || !secondParticipationText || !link) {
            alert('Por favor, rellene todos los campos.');
            return;
        }
        if(file && !file.name.endsWith('.webp')) {
            alert('Por favor, suba una imagen en formato .webp');
            return;
        }
        onAccepted({ title, file, hasTotal, total, firstParticipationText, secondParticipationText, link, id, canBeBroughtToHome });    };

    return (
        <Modal ariaHideApp={false} isOpen={isOpen} style={{ content: { margin: 'auto', width: '80%', border: '1px solid #ccc', padding: '20px' } }}>
            <Row className="mb-3">
                <Col xs={{ span: 2, offset: 8 }}>
                    <Button onClick={handleAccepted} variant="success" style={{ marginRight: '10px' }}>Importar</Button>
                </Col>
                <Col xs={{ span: 2 }}>
                    <Button onClick={onCancel} variant="danger">Cancelar</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormLabel htmlFor="titleFormText">Título</FormLabel>
                    <FormControl type="text" id="titleFormText" className="mb-3" onChange={handleTitleChange} value={title}></FormControl>
                    
                    <FormCheck type="checkbox" label="¿Tiene precio?" onChange={handleHasTotalChange} className="mb-3" checked={hasTotal} value={hasTotal} />
                    {hasTotal && (
                        <>
                            <FormLabel htmlFor="priceFormInput">Precio</FormLabel>
                            <FormControl type="number" id="priceFormInput" className="mb-3" onChange={handleTotalChange} value={total}></FormControl>
                        </>
                    )}
                    
                    <FormLabel htmlFor="descriptionFormText1">Primera descripción</FormLabel>
                    <FormControl as="textarea" id="descriptionFormText1" className="mb-3" onChange={handleFirstDescriptionChange} value={firstParticipationText}></FormControl>
                    
                    <FormLabel htmlFor="descriptionFormText2">Segunda descripción</FormLabel>
                    <FormControl as="textarea" id="descriptionFormText2" className="mb-3" onChange={handleSecondtDescriptionChange} value={secondParticipationText}></FormControl>
                    
                    <FormCheck type="checkbox" label="¿Lo pueden traer?" onChange={handleCanBeBroughtToHome} className="mb-3" checked={canBeBroughtToHome} value={canBeBroughtToHome} />

                    <FormLabel htmlFor="urlFormText">URL del artículo</FormLabel>
                    <FormControl type="text" id="urlFormText" className="mb-3" onChange={handleUrlChange} value={link}></FormControl>
                    
                    <FormControl type="file" id="fileFormInput" className="mb-3" onChange={handleFileChange}></FormControl>
                    {preview && <img src={preview} alt="Preview" style={{ width: '100%', height: 'auto' }} />}
                </Col>
            </Row>
        </Modal>
    );
}