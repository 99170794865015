const GUEST_BULK_URL = "https://backend.moliclau.com/guests/bulk"
const GUEST_URL = "https://backend.moliclau.com/guests"

export async function addGuests(guests) {
    return (await fetch(GUEST_BULK_URL, {
        method: "PUT", body: JSON.stringify(guests), headers: {
            "Content-Type": "application/json",
        }
    }))

}

export async function getGuests() {
    return (await fetch(GUEST_BULK_URL, { method: "GET" })).json();
}


export async function deleteGuest(id) {
    return (await fetch(GUEST_URL.concat(`/${id}`), { method: "DELETE" }))
}

export async function deleteGuests(ids) {
    return (await fetch(GUEST_BULK_URL, {
        method: "DELETE", body: JSON.stringify(ids), headers: {
            "Content-Type": "application/json",
        }
    }))
}