import { saveAs } from 'file-saver';

const Excel = require('exceljs');

const GUESTS_SHEET = 1;
const NAME_COLUM = 3;
const LASTNAME_COLUM = 4;
const EXPORT_COLUM = 5;
const CONFIRMATION_COLUM = 7;
const PRE_WEDDING_CANDIDATE_COLUM = 8;
const PRE_WEDDING_ASSIST_COLUM = 9;
const MENU_KIND_COLUM = 13;
const ALLERGIES_COLUM = 14;
const FROM_BUS_COLUM = 15;
const RETURN_BUS_COLUM = 16;


export function getGuestsFromExcel(file, onGuestsRead) {
    const workbook = new Excel.Workbook();
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = () => {
        const buffer = reader.result;
        workbook.xlsx.load(buffer).then(workbook => {
            let guests = []
            workbook.getWorksheet(GUESTS_SHEET).eachRow(row => {
                if (row.getCell(EXPORT_COLUM).value === 'Sí') {
                    guests.push({ 
                        name: row.getCell(NAME_COLUM).value?.trim(), 
                        lastName: row.getCell(LASTNAME_COLUM).value?.trim(),
                        preWeddingCandidate: mapSiNo(row.getCell(PRE_WEDDING_CANDIDATE_COLUM).value)
                    });
                }
            });
            onGuestsRead(guests);
        });
    }
}

export function updateExcelFile(file, guests) {
    const workbook = new Excel.Workbook();
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = () => {
        const buffer = reader.result;
        workbook.xlsx.load(buffer).then(workbook => {
            workbook.getWorksheet(GUESTS_SHEET).eachRow(row => {
                guests.filter(guest => guest.name === row.getCell(NAME_COLUM).value?.trim() && guest.lastName === row.getCell(LASTNAME_COLUM).value?.trim())
                    .forEach(guest => {
                        row.getCell(CONFIRMATION_COLUM).value = mapTrueFalse(guest.confirmed)
                        row.getCell(PRE_WEDDING_CANDIDATE_COLUM).value = mapTrueFalse(guest.preWeddingCandidate)
                        row.getCell(PRE_WEDDING_ASSIST_COLUM).value = mapTrueFalse(guest.preWeddingConfirmed)
                        row.getCell(MENU_KIND_COLUM).value = guest.menu
                        row.getCell(ALLERGIES_COLUM).value = guest.allergies
                        row.getCell(FROM_BUS_COLUM).value = mapTrueFalse(guest.fromBus)
                        row.getCell(RETURN_BUS_COLUM).value = mapReturnBus(guest.returnBus, guest.returnBusTime)
                    });
                row.commit();
            });
            workbook.xlsx.writeBuffer().then((data) => {
                var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                saveAs(blob, "bodorrio.xlsx")
            });
        });
    }
}

function mapTrueFalse(isTrue) {
    if (isTrue) {
        return "Sí";
    }
    else {
        return "No";
    }
}

function mapSiNo(value) {
    return value === "Sí";
}

function mapReturnBus(returns, time) {
    if (!returns) {
        return "No";
    }
    else {
        return time;
    }
}
