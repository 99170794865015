import Modal from "react-modal"
import { Row, Col, Button, Table, FormCheck } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { getParticipations, removeParticipation } from "./weddingListClient";


export default function ParticipationModal({ isOpen, onAccepted, onCancel, initialArticle }) {
    const [participations, setParticipations] = useState([]);

    useEffect(() => {
        if (initialArticle.id) {
            getParticipations(initialArticle.id)
                .then(participations => participations.json())
                .then(participations => {
                    setParticipations(participations);
                });
        }
    }, [initialArticle])


    function deleteParticipation(id) {
        removeParticipation(initialArticle.id, id)
            .then(() => {
                setParticipations(participations.filter(participation => participation.id !== id));
            })
    }


    return (
        <Modal ariaHideApp={false} isOpen={isOpen} style={{ content: { margin: 'auto', width: '80%', border: '1px solid #ccc', padding: '20px' } }}>
            <Row className="mb-3">
                <Col xs={{ span: 2, offset: 8 }}>
                    <Button onClick={() => onAccepted(participations)} variant="success" style={{ marginRight: '10px' }}>Aceptar</Button>
                </Col>
                <Col xs={{ span: 2 }}>
                    <Button onClick={onCancel} variant="danger">Cancelar</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre del participante</th>
                                <th>Cantidad</th>
                                <th>Validado</th>
                                <th>Gracias dadas</th>
                                <th>Lo traen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {participations.map((participation, index) => (
                                <tr key={index}>
                                    <td>
                                        <Button variant="danger" onClick={() => deleteParticipation(participation.id)}>Eliminar</Button>
                                    </td>
                                    <td>{participation.participantName}</td>
                                    <td>{participation.participation}</td>
                                    <td><FormCheck type="checkbox" checked={participation.validated} onChange={() => {
                                        participation.validated = !participation.validated
                                        setParticipations([...participations])
                                    }} readOnly /></td>
                                    <td><FormCheck type="checkbox" checked={participation.thanksGiven} onChange={() => {
                                        participation.thanksGiven = !participation.thanksGiven
                                        setParticipations([...participations])
                                    }} readOnly /></td>
                                    <td><span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: participation.bringToHome ? "green" : "red" }}></span></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Modal>
    );
}