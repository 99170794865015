import { FormControl } from "react-bootstrap";

function FileSelector({ onFileSelected }) {

    const handleFileChange = (event) => {
        if (event.target.files) {
            onFileSelected(event.target.files[0])
        }
    };

    return (
        <div>
            <FormControl type="file" onChange={handleFileChange} />
        </div>
    );
}

export default FileSelector;
