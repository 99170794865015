import FormData from "form-data"

const WEDDING_LIST_URL = "https://backend.moliclau.com/weddingList"
// const WEDDING_LIST_URL = "http://localhost/weddingList"
const WEDDING_LIST_ITEM_URL = "https://backend.moliclau.com/weddingListItem"
// const WEDDING_LIST_ITEM_URL = "http://localhost/weddingListItem"


export async function deleteItem(id) {
    return await fetch(`${WEDDING_LIST_ITEM_URL}/${id}`, {
        method: "DELETE"
    });

}

export async function getItems() {
    return await fetch(WEDDING_LIST_URL);
}

export async function addItem(article) {
    const formData = new FormData();
    formData.append('file', article.file);
    formData.append('item', new Blob([JSON.stringify(
        {
            "title": article.title,
            "hasTotal": article.hasTotal,
            "total": article.total,
            "link": article.link,
            "firstParticipationText": article.firstParticipationText,
            "secondParticipationText": article.secondParticipationText,
            "canBeBroughtToHome": article.canBeBroughtToHome
        }
    )], { type: "application/json" }))


    return (await fetch(WEDDING_LIST_URL,
        {
            method: "POST",
            body: formData,
            mode: "no-cors",
            headers: {
            }
        }
    ));

}

export async function updateItem(article) {
    const formData = new FormData();
    formData.append('file', article.file);
    formData.append('item', new Blob([JSON.stringify(
        {
            "title": article.title,
            "hasTotal": article.hasTotal,
            "total": article.total,
            "link": article.link,
            "firstParticipationText": article.firstParticipationText,
            "secondParticipationText": article.secondParticipationText,
            "canBeBroughtToHome": article.canBeBroughtToHome
        }
    )], { type: "application/json" }))


    return (await fetch(`${WEDDING_LIST_ITEM_URL}/${article.id}`,
        {
            method: "PUT",
            body: formData,
            headers: {}
        }
    ));
}


export async function getParticipations(articleId) {
    return await fetch(`${WEDDING_LIST_ITEM_URL}/${articleId}/participations`);
}

export async function updateParticipation(articleId, participation) {
    return await fetch(`${WEDDING_LIST_ITEM_URL}/${articleId}/participations/${participation.id}`,
        {
            method: "PUT",
            body: JSON.stringify(participation),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}   

export async function removeParticipation(articleId, participationId) {
    return await fetch(`${WEDDING_LIST_ITEM_URL}/${articleId}/participations/${participationId}`,
        {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}   