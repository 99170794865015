import TableWithFilters from "../tableWithFilters"
import AppMenu from "../appMenu";
import { Button, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import FileSelector from "../fileSelector";
import { getGuestsFromExcel, updateExcelFile } from "../excelService";
import { getGuests, addGuests, deleteGuest, deleteGuests } from "./guestsClient";
import GuestDifferencesModal from "./guestsDifferencesModal";


function deleteAGuest(guestToRemove, currentGuests, forceGuestsUpdate) {
    if(!window.confirm(`Seguro que quieres eliminar a ${guestToRemove.name} ${guestToRemove.lastName}?`)){
        return;
    }

    deleteGuest(guestToRemove.id);
    let guests = [...currentGuests]
    guests.splice(guests.findIndex(guest => guestToRemove.id === guest))
    forceGuestsUpdate(guests);
}

function getGuestsToBeInserted(existantGuests, guestsFromExcel) {
    return guestsFromExcel.filter(newGuest => !existantGuests.some(existantGuest => existantGuest.name === newGuest.name && existantGuest.lastName === newGuest.lastName && existantGuest.preWeddingCandidate === newGuest.preWeddingCandidate))
        .map(guest => {
            return {
                name: guest.name,
                lastName: guest.lastName,
                confirmed: false,
                menu: "GENERAL",
                allergies: "",
                fromBus: false,
                returnBus: false,
                returnBusTime: "",
                preWeddingCandidate: guest.preWeddingCandidate,
                preWeddingConfirmed: false,
            }
        });
}


function getGuestsToBeRemoved(currentGuests, guestsFromExcel) {
    return currentGuests
        .filter(currentGuest => !guestsFromExcel.some(newGuest => currentGuest.name === newGuest.name && currentGuest.lastName === newGuest.lastName && currentGuest.preWeddingCandidate === newGuest.preWeddingCandidate))
}

function updateGuestList(newGuests, guestToRemove, forceUpdateGuestsState) {
    addGuests(newGuests).then(() => {
        deleteGuests(guestToRemove.map(guest => guest.id)).then(() => {
            forceUpdateGuestsState(newGuests)
        });
    });
}

function formatGuests(guests) {
    guests.forEach(guest => {
        if(!guest.preWeddingCandidate){
            guest.preWeddingCandidate = false;
        }
    });
    return guests;
}

function mapBusTime(value) {
    if(value === "FIRST"){
        return "02:00 (first)";
    }
    if (value === "SECOND"){
        return "04:00 (second)";
    }
    if (value === "LAST") {
        return "06:00 (last)";
    }
    
    return value;
}


function GuestsConfirmations() {
    const [guests, setGuests] = useState([])
    const [guestUpdates, forceUpdateGuestsState] = useState([])
    const [file, setFile] = useState([])
    const [modalIsOpen, openModal] = React.useState(false);
    const [guestsToInsert, setGuestToInsert] = React.useState([])
    const [guestsToRemove, setGuestToRemove] = React.useState([])

    useEffect(() => {
        getGuests()
        .then(guests => formatGuests(guests))
        .then(guests => setGuests(guests))
    }, [guestUpdates])

    return (
        <div>
            <AppMenu />
            <GuestDifferencesModal
                isOpen={modalIsOpen}
                onAccepted={() => { 
                    openModal(false)
                    updateGuestList(guestsToInsert, guestsToRemove, forceUpdateGuestsState) 
                }}
                onCancel={() => {
                    openModal(false)
                    setGuestToInsert([]);
                    setGuestToRemove([]);
                }}
                guestsToBeInserted={guestsToInsert}
                guestsToBeRemoved={guestsToRemove}
            ></GuestDifferencesModal>
            <Row>
                <Col xs={4}>
                    <FileSelector onFileSelected={file => setFile(file)} />
                </Col>
                <Col>
                    <Button onClick={() => getGuestsFromExcel(file, (guestsFromExcel) => {
                        setGuestToInsert(getGuestsToBeInserted(guests, guestsFromExcel));
                        setGuestToRemove(getGuestsToBeRemoved(guests, guestsFromExcel));
                        openModal(true);
                    })}>Ver diferencias</Button>
                </Col>
                <Col xs={1}>
                    <Button onClick={() => updateExcelFile(file, guests)} >Exportar</Button>
                </Col>
                <Col xs={1}>
                    <Button onClick={() => forceUpdateGuestsState({})} >Refrescar</Button>
                </Col>
            </Row>
            <TableWithFilters data={guests} columns={[
                {
                    id: "delete",
                    Header: 'Borrar',
                    Cell: ({ cell }) => <Button variant="outline-danger" onClick={() => deleteAGuest(cell.row.original, guests, forceUpdateGuestsState)}>BORRAR</Button>,
                    hasFilter: false
                },
                {
                    Header: 'Nombre',
                    accessor: 'name',
                    hasFilter: true
                },
                {
                    Header: 'Apellidos',
                    accessor: 'lastName',
                    hasFilter: true
                },
                {
                    Header: 'Confirmado',
                    accessor: 'confirmed',
                    Cell: ({ value }) => (
                        <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: value ? "green" : "red" }}></span>
                    ),
                    hasFilter: true
                },
                {
                    Header: 'Cdto. Preboda',
                    accessor: 'preWeddingCandidate',
                    Cell: ({ value }) => (
                        <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: value ? "green" : "red" }}></span>
                    ),
                    hasFilter: true
                },
                {
                    Header: 'PreBoda',
                    accessor: 'preWeddingConfirmed',
                    Cell: (cell) => {
                        if(!cell.row.original["preWeddingCandidate"]){
                            return <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "gray" }}></span>
                        }
                        return <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: cell.value ? "green" : "red" }}></span>
                    },
                    hasFilter: true
                },
                {
                    Header: 'Menú',
                    accessor: 'menu',
                    hasFilter: true
                },
                {
                    Header: 'Alergias',
                    accessor: 'allergies',
                    hasFilter: true
                },
                {
                    Header: 'Bus Ida',
                    accessor: 'fromBus',
                    Cell: ({ value }) => (
                        <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: value ? "green" : "red" }}></span>
                    ),
                    hasFilter: true
                },
                {
                    Header: 'Bus Vuelta',
                    accessor: 'returnBus',
                    Cell: ({ value }) => (
                        <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: value ? "green" : "red" }}></span>
                    ),
                    hasFilter: true
                },
                {
                    Header: 'Hora Bus',
                    accessor: 'returnBusTime',
                    hasFilter: true,
                    Cell: ({ value }) => (
                        <span>{mapBusTime(value)}</span>
                    ),
                },
                {
                    Header: 'Actualizado',
                    accessor: 'writtenAt',
                    Cell: ({ value }) => <span>{value.replace("T", " ")}</span>
                },
            ]} />
        </div >
    );

}

export default GuestsConfirmations;